let mainNavLinks = document.querySelectorAll("nav ul li a"),
    lastScrollPos = 0
window.addEventListener("scroll", event => {
  let fromTop = window.scrollY
  mainNavLinks.forEach(link => {

    let section = document.querySelector(link.hash)
    if (
      section.offsetTop <= fromTop &&
      section.offsetTop + section.offsetHeight > fromTop
    ) {
      link.classList.add("current")
    } else {
      link.classList.remove("current")
    }

    // newScrollPos = window.pageYOffset;
    // if(lastScrollPos - newScrollPos < 0){
    //   if (body.classList.contains("scroll-up")) body.classList.remove("scroll-up")
    // } else if(lastScrollPos - newScrollPos > 0){
    //   if (!body.classList.contains("scroll-up")) body.classList.add("scroll-up")
    // }
    // lastScrollPos = newScrollPos

  })
})